<template>
  <div class="row q-pa-md bg-grey-2">
    <span class="col-6 text-h4">
      {{ title }}
      <addButton @add="clicked_add" />
    </span>
    <q-input
      v-if="has_search"
      class="col-6 self-end"
      type="text"
      dense
      label="search"
      v-model="search"
      @input="$emit('input', search)"
    >
      <template v-slot:prepend>
        <q-icon name="fad fa-search" color="primary" />
      </template>

      <template v-slot:append>
        <q-btn
          v-if="search.length"
          icon="far fa-times"
          round
          dense
          flat
          color="grey"
          @click="search = ''"
        />
      </template>
    </q-input>
  </div>
</template>
<script>
import addButton from "@/admin/components/addButton";
//console.log("ab: ", addButton);

export default {
  name: "content-header",
  props: ["title"],
  components: { addButton },
  computed: {
    has_search() {
      // if parent has no @input don't render the search
      return this.$listeners && this.$listeners.input;
    },
  },
  methods: {
    clicked_add() {
      console.log("add clicked via contentheader");
      this.$emit("add");
    },
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>
